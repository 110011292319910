import $ from 'jquery';
import whatInput from 'what-input';

window.$ = $;

import Foundation from 'foundation-sites';
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


// Function to validate password
function validatePassword(
  $el,
  required,
  parent
) {
  // var passwordInput = document.getElementById('password');
  var password = $el.val();
	var pwMessageID = [];
	var allpwMessageID = ['pw_val_len', 'pw_val_up', 'pw_val_lw', 'pw_val_num', 'pw_val_sp'];

	// Define password requirements
	var minLength = 15;
  (typeof window.minPasswordLength !== 'undefined' && !isNaN(window.minPasswordLength))
    minLength = window.minPasswordLength;
	var hasUppercase = /[A-Z]/.test(password);
	var hasLowercase = /[a-z]/.test(password);
	var hasNumber = /\d/.test(password);
	var hasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password);

	// Check if password meets requirements
	if (password.length < minLength) {
	  pwMessageID.push('pw_val_len');
	}
	if (!hasUppercase) {
	  pwMessageID.push('pw_val_up');
	}
	if (!hasLowercase) {
	  pwMessageID.push('pw_val_lw');
	}
	if (!hasNumber) {
	  pwMessageID.push('pw_val_num');
	}
	if (!hasSpecialChar) {
	  pwMessageID.push('pw_val_sp');
	}

	// If any validation error messages remain in the array, return them
	if (pwMessageID.length > 0) {
	  console.log('invalid: ');
    allpwMessageID.forEach(function(id) {
      $('#'+id).addClass('hide');
    });
	  pwMessageID.forEach(function(message) {
      $('#'+message).removeClass('hide');
	  });
	  return false;
	} else {
	  // Password is valid
	  console.log('valid');
	  return true;
	}

}

Foundation.Abide.defaults.validators['validate_password'] = validatePassword;



function customValidatorMin(
  $el,
  required,
  parent
) {
  if (!required) return true;
  var from = $el.attr('data-greater-than'),
      to = $el.val();

  return (parseInt(to) >= parseInt(from));
};

Foundation.Abide.defaults.validators['greater_than'] = customValidatorMin;

function customValidatorMax(
  $el,
  required,
  parent
) {
  if (!required) return true;
  var from = $el.attr('data-smaller-than'),
      to = $el.val();

  return (parseInt(to) <= parseInt(from));
};

Foundation.Abide.defaults.validators['smaller_than'] = customValidatorMax;

function customValidatorNotEqualTo($el) {
    var notequalto = $el.attr('data-abide-validator-notequalto');

    var content = $('#'+notequalto).val();
    if ($el.val() != content) {
      return true;
    } else {
      return false;
    }
}

Foundation.Abide.defaults.validators['notequalto'] = customValidatorNotEqualTo;

function customValidatorNotEqualToClass($el) {

    var notequaltoClass = $el.attr('data-abide-validator-notequaltoclass');

   $('.'+notequaltoClass).each(function(i, obj) {

	    //dont check for $el
	    if($el.attr('name') != $(this).attr('name')) {
        var content = $(this).val();
        if ($el.val() == content) {
          return false;
        }
      }
   });
   return true;
}

Foundation.Abide.defaults.validators['notequaltoclass'] = customValidatorNotEqualToClass;



$(document).foundation();

// disable foundation dropdown function for bigger devices than small
if(Foundation.MediaQuery.current != 'small') {
  $('#main-menu').foundation('_destroy');
}

// watch for breakpoint changes
$(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
  // newSize is the name of the now-current breakpoint, oldSize is the previous breakpoint

  // if disable foundation dropdown function for bigger devices than small
  if(newSize != 'small') {
    $('#main-menu').foundation('_destroy');
  }
  else
    location.reload();
});




$( ".select-roles" ).change(function() {
  var id = "5";
    $("option:selected", this).each(function() {
      id = $( this ).val();
      id = id.substring(id.length - 1);
    });
    $( this ).removeClass().addClass('select_show select-roles role-'+id );
}).trigger( "change" );

function addDetailRow() {
  // Step 1: Get all input elements whose name contains "detail_columns_"
var inputs = $('input[name*="detail_columns_"]');

// Step 2: Extract the numeric part from the value of each input element
// console.log(numbers);
var numbers = inputs.map(function() {
  var value = $(this).val();
  return value ? parseInt(value, 10) : null;
}).get();

// Step 3: Find the highest value in the array of numbers
var rowNum = Math.max.apply(null, numbers);

// Step 4: Handle the case where rowNum is undefined or no valid numbers were found
if(typeof rowNum === 'undefined' || rowNum === -Infinity || isNaN(rowNum)) {
    rowNum = 0;
}

// console.log(rowNum);




  var newRowNum = rowNum;
  newRowNum++;

  const children = $('#detail_field_types_select_2').children();

  $('#detailRows').append('<div class="grid-x"><div class="small-2 cell"><div class="input-group"><span class="input-group-label bg-quarternary">Ref</span><input type="hidden" name="detail_columns_'+newRowNum+'" value="'+newRowNum+'"><input class="no-margin-bottom" type="text" value="'+newRowNum+'" disabled></div></div><div class="small-3 cell"><select name="detail_field_types_'+newRowNum+'" id="detail_field_types_select_'+newRowNum+'" class="detail_field_types_select"></select></div><div class="small-5 cell"><textarea name="detail_headers_'+newRowNum+'"></textarea></div><div class="small-1 cell"><input type="hidden" name="detail_webform_selected_'+newRowNum+'" value="off"><div class="switch tiny inline-block">    <input class="switch-input" type="checkbox" id="detailed_showInForm_switch_'+newRowNum+'" name="detail_webform_selected_'+newRowNum+'" checked><label class="switch-paddle" for="detailed_showInForm_switch_'+newRowNum+'"><span class="show-for-sr">in webform<br></span><span class="switch-active" aria-hidden="true"></span><span class="switch-inactive" aria-hidden="true"></span></label></div></div><div class="small-1 cell"><div class="switch tiny"><input class="switch-input" type="checkbox" id="detailed_header_mandatory_switch_'+newRowNum+'" name="detail_mandatory_'+newRowNum+'" checked><label class="switch-paddle" for="detailed_header_mandatory_switch_'+newRowNum+'"><span class="show-for-sr">mandatory<br></span><span class="switch-active" aria-hidden="true"></span><span class="switch-inactive" aria-hidden="true"></span></label></div></div></div>');

  children.each(function(index) {
    $(this).clone().appendTo('#detail_field_types_select_'+newRowNum+'');
  });
  $('input[name="detail_field_types_'+newRowNum+'"]').prop('selectedIndex',0);
  $('select[name="detail_field_types_'+newRowNum+'"]').focus();
}

function addFormField() {
  var rowNum = $('.detailRow:last').attr('id');
  // var rowNum = $(this).attr('name');
  console.log("last from id "+rowNum);
  if(typeof rowNum === 'undefined')
    rowNum = 0;
  else {
    const rowNumSplit = rowNum.split("_");
    var key = rowNumSplit.length - 1;
    rowNum = rowNumSplit[rowNumSplit.length - 1];
    console.log("last else "+rowNum);
  }
  var newRowNum = rowNum;
  newRowNum++;
  if(newRowNum < 1)
    newRowNum = 1;
console.log("new "+newRowNum);

  const children = $('#detail_field_types_select_1').children();

  $('#detailRows').append('<div class="grid-x detailRow" id="detailRow_'+newRowNum+'"><div class="small-2 cell"><div class="input-group"><span class="input-group-label bg-quarternary">Ref</span><input type="hidden" name="detail_columns_'+newRowNum+'" value="'+newRowNum+'"><input class="no-margin-bottom" type="text" value="'+newRowNum+'" disabled></div></div><div class="small-3 cell"><select name="detail_field_types_'+newRowNum+'" id="detail_field_types_select_'+newRowNum+'" class="detail_field_types_select"></select></div><div class="small-5 cell"><textarea name="detail_headers_'+newRowNum+'"></textarea></div><div class="small-1 cell"><div class="switch tiny"><input class="switch-input" type="checkbox" id="detailed_header_mandatory_switch_'+newRowNum+'" name="detail_mandatory_'+newRowNum+'" checked><label class="switch-paddle" for="detailed_header_mandatory_switch_'+newRowNum+'"><span class="show-for-sr">mandatory<br></span><span class="switch-active" aria-hidden="true"></span><span class="switch-inactive" aria-hidden="true"></span></label></div></div></div>');

  children.each(function(index) {
    $(this).clone().appendTo('#detail_field_types_select_'+newRowNum+'');
  });
  $('input[name="detail_field_types_'+newRowNum+'"]').prop('selectedIndex',0);
  $('select[name="detail_field_types_'+newRowNum+'"]').focus();
}

$('#addDetailRow').click(function() {
  addDetailRow();
});

$('#addFormField').click(function() {
  addFormField();
});

document.addEventListener("keydown", e => {
  // console.log(e);
  if(e.key === 'a' && e.ctrlKey === true) {
    addDetailRow();
  }
});

$('#detailRows').on('change','.detail_field_types_select', function() {
  var rowNum = $(this).attr('name');
  if(typeof rowNum === 'undefined')
    rowNum = 0;
  else {
    const rowNumSplit = rowNum.split("_");
    var key = rowNumSplit.length - 1;
    rowNum = rowNumSplit[rowNumSplit.length - 1];
  }

  if($(this).val() == "select" || $(this).val() == "slider") { // for custom_select
    $(this).parent().append('<input type="text" name="custom_select_values_'+rowNum+'" placeholder="values, comma separated">');
    $('input[name="detail_headers_'+rowNum+'"]').parent().append('<div id="custom_select_toggleID_switch_div_'+rowNum+'"><div class="switch tiny inline-block"><input class="switch-input" type="checkbox" id="custom_select_toggleID_switch_'+rowNum+'" name="custom_select_toggleID_'+rowNum+'"><label class="switch-paddle" for="custom_select_toggleID_switch_'+rowNum+'"><span class="show-for-sr">active_toggleIDs<br></span><span class="switch-active" aria-hidden="true"></span><span class="switch-inactive" aria-hidden="true"></span></label></div> options toggle form fields</div>')
  }
  else if($(this).val() == "switch") { // for yesNo_switch
    $('input[name="detail_columns_'+rowNum+'"]').parent().append('<p id="yesNo_switch_expl_'+rowNum+'">form elements to show/hide when yes')
    $(this).parent().append('<input type="text" name="yesNo_switch_values_'+rowNum+'" placeholder="show on yes comma separated">');
    $(this).parent().append('<input type="text" name="yesNo_switch_rev_values_'+rowNum+'" placeholder="hide on yes comma separated">');
    $('#detailed_header_mandatory_switch_'+rowNum+'').prop( "checked", false ).parent().hide();
  }
  else {
    // for custom_select
    $('input[name*="custom_select_values_'+rowNum+'"]').remove();
    $('#custom_select_toggleID_switch_div_'+rowNum+'').remove();
    // for yesNo_switch
    $('input[name*="yesNo_switch_values_'+rowNum+'"]').remove();
    $('#yesNo_switch_expl_'+rowNum+'').remove();
    $('#detailed_header_mandatory_switch_'+rowNum+'').prop( "checked", true ).parent().show();
  }
});

$('.select_toggle').on('change', function() {
  var value = $(this).val();
  const options = $(this).children("option");
  $('#row_'+value).toggleClass('hide');
});


function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

$('.copyToClipboard').click(function() {
  var codeElementID = $(this).attr('data-copyToClipboard');
  var copyText = $('#'+codeElementID).html();
  copyText = decodeHtml(copyText);
  copyText = copyText.replace('<pre>','');
  copyText = copyText.replace('</pre>','');
  navigator.clipboard.writeText(copyText);

  $(this).append(' <i class="fas fa-check"></i>').fadeIn();
});

function toggleFields_select(elem, value) {
  const options_obj = elem.children("option");
  const options = options_obj.each(function() {
    var option = $(this).attr('value');
    if(!$(this).hasClass('hide'))
      $('#row_'+option).addClass('hide').trigger('classChange');
  });
  if($('#row_'+value).hasClass('hide'))
    $('#row_'+value).removeClass('hide').trigger('classChange');
}

function toggleFields_switch(elem) {
  const toggles_arr = elem.attr('data-toggleIDs').split(" ");
  $.each(toggles_arr, function(k,v) {
    var tg_elem = $('#'+v);
    if(tg_elem.hasClass('hide'))
      tg_elem.removeClass('hide').trigger('classChange');
    else
      tg_elem.addClass('hide').trigger('classChange');
  });
}

$('.row').on("classChange", function() {
  if(!$(this).hasClass('hide'))
    $(this).find('input').prop('required',true);
  else
    $(this).find('input').prop('required',false);

});


$('.select_show').on('change', function() {
    var attr = $(this).find(":selected").attr('data-toggle');;
    var elemID = $(this).children('.toggle_show').attr('data-toggle');
    var elemClassHide = $(this).children('.toggle_show').attr('data-hide');
    $('.'+elemClassHide).addClass('hide');
    if(typeof attr !== 'undefined' && attr !== false)
      $('#'+elemID).removeClass('hide');
    else
      $('#'+elemID).addClass('hide');
});

$('.select_reveal').on('change', function() {
    var attr = $(this).find(":selected").attr('data-toggle');;
    var elemID = $(this).children('.toggle_reveal').attr('data-toggle');
    if(typeof attr !== 'undefined' && attr !== false)
      $('#'+elemID).foundation('open');
    else
      $('#'+elemID).foundation('close');
});

$('.select_toggle').on('change', function() {
  toggleFields_select($(this), $(this).val());
});

$('.switch-has-toggles').on('change', function() {
  toggleFields_switch($(this));
});


// data-show-hide to specifically show or hide a target element
$('input[data-show-hide]').on('click', function() {
  var target = $(this).attr('data-show');
  var action = 'show';
  if(target === undefined) {
    var target = $(this).attr('data-hide');
    action = 'hide';
  }

  var status = 'visible';
  if($('#'+target).hasClass('hide'))
    status = 'hidden';

  if(action == 'show' && status == 'hidden')
    $('#'+target).removeClass('hide');

  if(action == 'hide' && status == 'visible')
    $('#'+target).addClass('hide');
});

$('.toggleRequired').on('change', function() {
  var field = $(this).attr('data-toggleRequired');
  var inputField = $('#'+field);
  // console.log(inputField.prop('required'));
  if(inputField.prop('required') != true) {
    inputField.prop('required',true);
  }
  else {
    inputField.prop('required',false);
  }
});

// currency input type  -------------------

$("input[data-type='currency']").on({
    keyup: function() {
      formatCurrency($(this));
    },
    blur: function() {
      formatCurrency($(this), "blur");
    }
});

$(".toggleAllCheckboxes").click(function() {
  var parentID = $(this).attr('data-parentID');
  var checkboxes = $('#'+parentID+' input[type=checkbox]');
  checkboxes.prop("checked", !checkboxes.prop("checked"));
});

function formatNumber(n) {
  // format number 1000000 to 1,234,567
  return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}

function formatCurrency(input, blur) {
  const input_val = input.val();
  if (!input_val) return;

  const original_len = input_val.length;
  const caret_pos = input.prop("selectionStart");

  const hasDecimal = input_val.includes(",");
  let [left_side, right_side = ""] = input_val.split(",");

  left_side = formatNumber(left_side);
  right_side = formatNumber(right_side);

  if (blur === "blur") {
    right_side = right_side.padEnd(2, '0');
  }

  if (hasDecimal) {
    right_side = right_side.slice(0, 2);
  } else if (blur === "blur") {
    right_side = "00";
  }

  const formatted_val = `€${left_side}${hasDecimal ? ',' + right_side : ''}`;
  input.val(formatted_val);

  const updated_len = formatted_val.length;
  input[0].setSelectionRange(updated_len - original_len + caret_pos, updated_len - original_len + caret_pos);
}

// currency input type END -------------------

$(document).ready(function(){
  // ajaxupload for loggedin
  function hashCode (str){
    var hash = 0;
    if (str.length == 0) return hash;
    for (var i = 0; i < str.length; i++) {
        var char = str.charCodeAt(i);
        hash = ((hash<<5)-hash)+char;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
  }

  $('select[sel-max]').change(function () {
    var list = 'multi_dropdown_'+$(this).attr('id');
    var selectedNum = $(this).children("select option:selected").length;
    if(selectedNum >= $(this).attr('sel-max')) {
      $('#'+list).children('div:not(.checked)').addClass('disabled-click').children('input[type="checkbox"]').attr("disabled", true);
    }
    else {
      $('#'+list).children('div').removeClass('disabled-click').children('input[type="checkbox"]').removeAttr("disabled");
    }
  });


  $('form.uploadinline input.file').change(function () {
    var file = 'file';
    var formID = $(this).parents('.uploadinline').attr('id');
    var ajax_upload_reload_page = $('#'+formID+' [name="ajax_upload_reload_page"]').val();
    console.log("formID: "+formID);
    var files = this.files;
    let uploadForm = document.getElementById(formID);
    let formData = new FormData(uploadForm);
    var fileCount = files.length;
    if(fileCount > 1)
      file = 'files';
    // $('form p').html(fileCount + " " + file + " selected");
    var filelist = $('#fileList');

    for (var i = 0; i < files.length; i++) {

      // get item
      file = files.item(i);
      //or
      file = files[i];

      var fileHash = 'file'+ hashCode(file.name);

      filelist.append('<p id="'+ fileHash +'">' + file.name + '</p>');

      var fileP = $('#'+ fileHash);
      var uploading = '<i class="fas fa-arrow-up primary"></i>';
      var uploaded = '<i class="fas fa-check success"></i>';
      var error = '<i class="fas fa-exclamation-triangle alert" title="File not uploaded!"></i>';
      var invalid = '<i class="fas fa-exclamation-triangle warning" title="Invalid File Type!"></i>';


      //then send to upload via ajax
      $.ajax({
        url: "View/ajaxupload.php",
        type: "POST",
        data: formData,
        contentType: false,
        cache: false,
        enctype: 'multipart/form-data',
        processData:false,
        beforeSend : function() {
          $('#fileUploaded').addClass('hide');
          fileP.append(uploading);
         },
         success: function(data) {
          fileP.children('.fa-arrow-up').fadeOut();
          if(data=='invalid') {
           // invalid file format.
           fileP.append(invalid).fadeIn();
          }
          else {
           fileP.append(uploaded).fadeIn();
           $("#uploadedFileIDs").val(function() { return this.value + ","+data });
           // $("#"+formID+" input.file")[0].reset();
           $('#fileUploaded').removeClass('hide').fadeIn();
           if(ajax_upload_reload_page == 'reload')
            location.reload();
          }
        },
        error: function(e) {
          fileP.append(error).fadeIn();
          // $("#err").html(e).fadeIn();
        }
      }); // end ajax upload
    }
  });

  // ajaxupload for loggedin END

  // disable orbit arrow keys
  Foundation.Keyboard.register('Orbit', {
    'ltr': {
      'ARROW_RIGHT': 'nnext',
      'ARROW_LEFT': 'pprevious'
    },
    'rtl': {
      'ARROW_LEFT': 'nnext',
      'ARROW_RIGHT': 'pprevious'
    }
  });

});

//for PWA
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/service-worker.js')
    .then(function(registration) {
      console.log('Service Worker registered with scope:', registration.scope);
    }).catch(function(error) {
      console.log('Service Worker registration failed:', error);
    });
}
